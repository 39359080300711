import React from 'react'
import OffNav2 from '../OffNav2'
import { navigate } from 'gatsby-link'


// import CoVid19 from '../CoVid19'
import EkoLogia from '../EkoLogia'
import BranDing from '../BranDing'
import ProjGraf from '../ProjGraf'
import StronyW from '../StronyW'

import KalKsia from '../KalKsia'
import KalFirm from '../KalFirm'
//import GadzetyRek from '../GadzetyRek'
import WydrukiRek from '../WydrukiRek'
import RekZew from '../RekZew'

import FotoProd from '../FotoProd'
import WystaWien from '../WystaWien'
import FilmoWanie from '../FilmoWanie'
import OpaKowania from '../OpaKowania'
import VirReal from '../VirReal'



class ModalFormX extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
      <OffNav2 />



      {/* Kalendarze książkowe */}

      <div className='boxshadow modalitem'  id='BookCalendars' style={{ display:'block'}}>
      <KalKsia />
      </div>

      <div className='boxshadow modalitem'  id='KalendarzeksiążkoweX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
      <div className='hero-body has-text-centered'>
      <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Book calendars" src='/img/check.svg'/>
        <p className='title' style={{color:'white'}}>
          The message has been sent.
        </p>
      </div>
      </div>


      {/* Ekologia */}

     <div className='boxshadow modalitem' id='Ecology' style={{ display:'block'}}>
     <EkoLogia />
     </div>

     <div className='boxshadow modalitem'  id='EkologiaX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="ecology" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* BranDing */}

     <div className='boxshadow modalitem' id='Branding' style={{ display:'block'}}>
     <BranDing />
     </div>

     <div className='boxshadow modalitem'  id='BrandingX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Branding" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Projektowanie graficzne */}

     <div className='boxshadow modalitem' id='Graphicdesign' style={{ display:'block'}}>
     <ProjGraf />
     </div>

     <div className='boxshadow modalitem'  id='ProjektowaniegraficzneX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Graphic design" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Web pages */}

     <div className='boxshadow modalitem' id='Webpages' style={{ display:'block'}}>
     <StronyW />
     </div>

     <div className='boxshadow modalitem'  id='StronyinternetoweX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="webpages" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* COVID19


     <div className='boxshadow modalitem'  id='COVID-19' style={{ display:'block'}}>
     <CoVid19 />
     </div>

     <div className='boxshadow modalitem'  id='COVID-19X' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="covid-19" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

      */}
     {/* Kalendarze firmowe */}

     <div className='boxshadow modalitem' id='Corporatecalendars' style={{ display:'block'}}>
     <KalFirm />
     </div>

     <div className='boxshadow modalitem'  id='KalendarzefirmoweX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Corporate calendars" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Gadżety reklamowe

     <div className='boxshadow modalitem' id='Gadżetyreklamowe' style={{ display:'block'}}>
     <GadzetyRek />
     </div>*/}

     <div className='boxshadow modalitem'  id='Advertisinggadgets' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Promotional gadgets" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>
     {/* Wydruki reklamowe */}

     <div className='boxshadow modalitem' id='Advertisingprints' style={{ display:'block',marginBottom:'50px'}}>
     <WydrukiRek />
     </div>

     <div className='boxshadow modalitem'  id='WydrukireklamoweX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Advertising prints" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Reklama zewnętrzna */}

     <div className='boxshadow modalitem' id='Outdooradvertising' style={{ display:'block'}}>
     <RekZew />
     </div>

     <div className='boxshadow modalitem'  id='ReklamazewnętrznaX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Outdoor advertising" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Fotografia produktowa */}

     <div className='boxshadow modalitem' id='Productphotography' style={{ display:'block'}}>
     <FotoProd />
     </div>

     <div className='boxshadow modalitem'  id='FotografiaproduktowaX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Product photography" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Wystawiennictwo */}

     <div className='boxshadow modalitem' id='Exhibit' style={{ display:'block'}}>
     <WystaWien />
     </div>

     <div className='boxshadow modalitem'  id='WystawiennictwoX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Exhibition" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Filmowanie */}

     <div className='boxshadow modalitem' id='Filming' style={{ display:'block'}}>
     <FilmoWanie />
     </div>

     <div className='boxshadow modalitem'  id='FilmowanieX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Filming" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* Opakowania */}

     <div className='boxshadow modalitem' id='Packaging' style={{ display:'block'}}>
     <OpaKowania />
     </div>

     <div className='boxshadow modalitem'  id='OpakowaniaX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="Packaging" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>

     {/* VR */}

     <div className='boxshadow modalitem' id='VR' style={{ display:'block'}}>
     <VirReal />
     </div>

     <div className='boxshadow modalitem'  id='VRX' style={{ display:'none',backgroundColor:'#00d1b2'}}>
     <div className='hero-body has-text-centered'>
     <img width="50px"  style={{verticalAlign:'middle',filter:'invert(1)',marginTop:'-50px',marginBottom:'15px'}}   alt="VR" src='/img/check.svg'/>
       <p className='title' style={{color:'white'}}>
         The message has been sent.
       </p>
     </div>
     </div>
















      </>
    )
  }
}



export { ModalFormX }
