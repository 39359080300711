import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { ModalFormX } from '../components/ModalOpen/ModalFormX'
import config from '../../config'
import Layout from '../components/Layout'

const Zamowienie = () => (
  <>
  <Layout>
  <Helmet
    htmlAttributes={{
      lang: `en-en`,
    }}
    meta={[
      {
        name: `viewport`,
        content: `width=device-width, initial-scale=1`,
      },
      {
        name: `msapplication-TileColor`,
        content: config.themeColor,
      },
      {
        name: `theme-color`,
        content: config.themeColor,
      },
    ]}
    link={[
      {
        rel: `apple-touch-icon`,
        href: `/icons/apple-touch-icon.png`,
        sizes: `180x180`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-32x32.png`,
        sizes: `32x32`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-16x16.png`,
        sizes: `16x16`,
      },
      {
        rel: `mask-icon`,
        href: `/icons/safari-pinned-tab.svg`,
        color: config.themeColor,
      },
    ]}
    link={[
      {
        rel: `preconnect`,
        href: `https://fonts.gstatic.com`,
      },
      {
        rel: `stylesheet`,
        href: `https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap`,
      },

    ]}
  >
  <title>Get a quote - free online pricing</title>
  <meta name="description" content="Get a quote - answer the questions to receive a free pricing for the services we offer within 24 hours. Check it out!" />
  <script type='application/ld+json'>
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://artopen.co/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Get a quote",
    }]
  })}
  </script>


   </Helmet>
  <div style={{minHeight:'100%',backgroundImage:'url(/img/tlo3.svg)',backgroundSize:'cover',paddingLeft:'5%',backgroundAttachment:'fixed',paddingRight:'5%',paddingTop:'5%',zIndex:'20000',fontFamily:'Poppins, sans-serif !important'}}>
  <nav className="breadcrumbs-nav" style={{marginBottom:'25px'}}>
  <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>Get a quote</b></span>
  </nav>

    <ModalFormX />

  <div style={{textAlign:'center',paddingBottom:'50px'}}>
  </div>
  </div>
  </Layout>
  </>

)


export default Zamowienie
