import React from 'react';
import { navigate } from 'gatsby-link';

const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});

function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}




class BranDing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }


  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleSubmit = e => {

    e.preventDefault()
    const form = e.target;


        let formdata
        formdata = encode({
         'form-name': form.getAttribute('name'),
         ...this.state,
       })


          for (let value of formdata.values()) {
             console.log(value);
          }

    fetch('/', {
      method: 'POST',
      body: formdata,
    })
      .then(() => {

        const messageData = {
          from: 'Art Open <bok@artopen.pl>',
          to: document.getElementById('adres email').value,
          subject: 'Thank you for sending your inquiry!',
          html: `
<div style="background-color: #23c495; text-align: center">
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/">
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo-mail.png?v=1651011958996"
    width="300px"
/></a>
</div>

<div
style="
  background-color: #23c495;
  display: flex;
  align-items: center;
  justify-content: center;
"
>
<div style="display: block; text-align: left; color: white; padding: 20px">
  <h1 style="font-size: 1.5em">Thank you!</h1>
  <h2
    style="
      border-radius: 5px;
      border-bottom: 15px solid #fcc515;
      font-size: 2em;
      max-width: 80%;
    "
  >
    For sending a message from the Art Open website
  </h2>
  <sub style="margin-bottom: 20px"
    >Our team<strong> will contact you shortly.</strong><br />
    On business days <strong>we usually complete quotes within 24 hours</strong>.</sub
  >
</div>
<div>
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://artopen.co/contact/"
    ><img src="https://artopen.co/img/newsletter.svg" width="100%"
  /></a>
</div>
</div>
<br />
<h3 style="font-size: 2em; text-align: center; margin-top: 30px">
Get to know us better:
<br />
<sub style="font-size: 0.5em"
  >Get to know
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/about-us/"
    >who we are</a
  >.</sub
>
</h3>

<div style="display: flex; justify-content: center">
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/offer/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l4.png?v=1651011237576"
      width="100%"
    /><br />
    <p style="text-align: center">OFFER</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/blog/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l3.png?v=1651011237576"
      width="100%"
    /><br />
    <p style="text-align: center">BLOG</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/portfolio/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l2.png?v=1651011237791"
      width="100%"
    /><br />
    <p style="text-align: center">PORTFOLIO</p>
  </div></a
>
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/catalogs/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l1.png?v=1651011237864"
      width="100%"
    /><br />
    <p style="text-align: center">CATALOGS</p>
  </div></a
>
</div>
<br /><br />
<h3 style="text-align: center; font-size: 2em">
Join the action:<br />
<sub style="font-size: 0.5em"
  >Check out
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/blog/cleaning-action-open-for-environment-v-2/"
    >action footage in 2021</a
  >.</sub
>
</h3>
<br /><br />
<div style="display: block; text-align: center">
<div>
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/akcja-sprzatanie-3.png?v=1651239639520"
    width="100%"
    style="min-width: 200px; max-width: 500px"
  />
</div>
<div
  style="
    display: block;
    text-align: center;
    padding-left: 0px;
    color: #23c495;
  "
>
  <h1 style="font-size: 1.5em">
    Be ECO with us and join the Cleanup Action vol.III
  </h1>
  <p style="max-width:90%;margin-left:5%;">
    Being aware of the seriousness of the problem of littering, while being an agency with a strong pro-ecological profile at the same time, we decided to take matters into our own hands and for the third time we are organizing a local cleaning action. 
    <br /><br /><br />
    <a
      style="
        color: #23c495;
        border-radius: 15px;
        padding: 10px 20px;
        border: 1px solid #23c495;
      "
      target="_blank"
      rel="noopener noreferrer"
      href="https://artopen.co/akcja-sprzatanie-vol-3/"
      >join us</a
    >
  </p>
</div>
</div>
<br /><br />
<h3 style="text-align: center; font-size: 2em">
Check out the blog:<br />
<sub style="font-size: 0.5em"
  >Read
  <a
    target="_blank"
    style="color: #23c495"
    rel="noopener noreferrer"
    href="https://artopen.co/blog/"
    >our articles</a
  >.</sub
>
</h3>
<div
style="
  background-color: #23c495;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
"
>
<div style="display: block; text-align: left; padding: 20px; color: white">
  <h1 style="font-size: 2em">How to create a website?</h1>
  <p>
  What does web development look like and what does it consist of? Nowadays, a company website is a must have no matter the company’s profile. However, considering the competition online, simply having a website may not be enough.
    <br /><br /><br />
    <a
      style="
        color: white;
        border-radius: 15px;
        padding: 10px 20px;
        border: 1px solid white;
      "
      target="_blank"
      rel="noopener noreferrer"
      href="https://artopen.co/blog/jak-stworzyc-strone-internetowa/"
      >read more</a
    >
  </p>
</div>
<div>
  <img
    src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/jak.png?v=1651241484223"
    width="100%"
  />
</div>
</div>
<br /><br /><br />
<div style="display: flex; justify-content: center">
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://facebook.com/artopenstudioreklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/fb.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://twitter.com/AReklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/t.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/yt.png?v=1651013201802"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.linkedin.com/company/art-open-studio-reklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l.png?v=1651013202010"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.behance.net/ArtOpenStudioReklamy/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/b.png?v=1651013202080"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
<a
  target="_blank"
  rel="noopener noreferrer"
  href="https://www.instagram.com/artopen.pl/"
  ><div>
    <img
      src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/i.png?v=1651013202263"
      width="30px"
    /></div></a
>&nbsp;&nbsp;
</div>

<p style="text-align: center; font-size: 10px; margin-top: 10px">
We hope you enjoy our content. If you don't
<a
  target="_blank"
  style="color: #23c495"
  rel="noopener noreferrer"
  href="https://artopen.co/contact/"
  >here you can withdraw your subscriptions.</a
>
</p>
<br /><br />

`
        };
        console.log(client)

        client.messages.create(DOMAIN, messageData)
         .then((res) => {
           console.log(res);
         })
         .catch((err) => {
           console.error(err);
         });


        navigate('/contact/sukces/')
      }).catch(error => alert(error))

  }




  render() {
    return (
      <>
      <form
        name="Branding"
        action="/contact/sukces/"
        id = "brandingform"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
      {/* Branding */}

      <input name='branding' id='branding' className='subtitle' placeholder='Branding:' disabled style={{color:'gray',fontFamily:'Poppins, sans-serif', backgroundColor:'white',border:'0px solid white'}} />

      <div className='field' style={{marginLeft:'18px'}}>
        <label className='label'>Company information:</label>
        <div className='control'>

          <textarea
          className='textarea' as='textarea' placeholder="Type of business, company characteristics, offer..."  name='informacje o firmie...' id='info'
          onChange={this.handleChange}
          rows="3"
          ></textarea>
        </div>

      </div>
      <br />





          <br />

      <div className='field' style={{marginLeft:'15px'}}>


          <div className='control  columns' style={{marginLeft:'3%',marginRight:'3%',flexWrap:'wrap'}}>

          <div className="column">
            <label htmlFor="logotyp" className='label main' style={{fontSize:'14px'}}>Logotype
              <input className='checkbox' type='checkbox' name='Logotyp' id='logotyp'
              onChange={this.handleChange} />
              <span className="check"></span>
              </label>
              </div>
              <div className="column">
              <label htmlFor="Identyfikacja wizualna" className='label main' style={{fontSize:'14px'}}>Visual Identification
                <input className='checkbox' type='checkbox' name='Identyfikacja wizualna znaku' id='Identyfikacja wizualna'
                onChange={this.handleChange} />
                <span className="check"></span>
                </label>
                </div>
                <div className="column">
                <label htmlFor="Nazwa firmy" className='label main' style={{fontSize:'14px'}}>Company name
                  <input className='checkbox' type='checkbox' name='Nazwa firmy' id='Nazwa firmy'
                  onChange={this.handleChange} />
                  <span className="check"></span>
                  </label>
                  </div>
                  <div className="column">
                  <label htmlFor="Slogany" className='label main' style={{fontSize:'14px'}}>Slogans
                    <input className='checkbox' type='checkbox' name='Slogany firmowy' id='Slogany'
                    onChange={this.handleChange} />
                    <span className="check"></span>
                    </label>
                    </div>
                    <div className="column">
                    <label htmlFor="Opracowywanie tekstów" className='label main' style={{fontSize:'14px'}}>Text processing
                      <input className='checkbox' type='checkbox' name='Opracowywanie tekstów' id='Opracowywanie tekstów'
                      onChange={this.handleChange} />
                      <span className="check"></span>
                      </label>
                      </div>
          </div>


        </div>



                {/* file input fields */}

                <hr />

                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="Branding" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field"
                        onChange={this.handleChange}
                        />
                      </label>
                    </div>

                    <div className="columns">
                    <div className='column' style={{marginLeft:'15px'}}>
                    <div className="field">
                      <label className="label" htmlFor={'name'}>
                        Name and Surname<sup>*</sup>:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'imię i nazwisko'}
                          onChange={this.handleChange}
                          id={'imię i nazwiskoI'}
                          required={true}
                        />
                      </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor={'email'}>
                          E-mail address<sup>*</sup>:
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={'email'}
                            name={'adres email'}
                            onChange={this.handleChange}
                            id={'adres email'}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="field ">
                        <label className="label" htmlFor={'phone'}>
                          Phone number<sup>*</sup>:
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={'tel'}
                            name={'numer telefonu'} required={true}
                            onChange={this.handleChange}
                            id={'nrtelI'}
                          />
                        </div>
                      </div>


                    </div>

                    <div className="field column" style={{marginLeft:'15px'}}>
                      <label className="label" htmlFor={'message'}>
                        Message<sup>*</sup>:
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          type={'text'}
                          name={'wiadomość'}
                          onChange={this.handleChange}
                          id={'wiadomośćI'}
                          required={true}
                          rows = "7"
                        ></textarea>
                      </div>



                      <br />
                      <div className="control">

                      <label style={{fontSize: '12px'}} className='main'  htmlFor="privacyI">   <input required={true} onChange={this.handleChange} type="checkbox" id="privacyI" name="privacy"  value="true"/>I consent to the processing of my data in accordance with our <a className='link-green' href="/privacy-policy/">privacy policy</a><sup>*</sup>.<span className="check"></span></label>

                      </div>

                      <div className="field" style={{textAlign:'right'}}>


                        <button className="button is-primary" type="submit" onSubmit={this.handleSubmit}>
                          Send
                        </button>



                      </div>


                    </div>



                    </div>

                </form>











      </>
    )
  }
}

export default BranDing;
