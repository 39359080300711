import React from 'react';
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'

function OffNav(props) {

  return (
    <>
    <div  style={{textAlign:'center',marginTop:'auto'}}>
    <Link to="/">
      <img width="200px" src='/img/ArtOpen.svg' alt="ArtOpen advertising studio" />
    </Link>
      <br />
    </div>
    <br />
    <h1 className="stays-dark" style={{textAlign:'center'}}>Please answer the questions of the form and get <b>free service pricing within 24 hours. </b></h1>
    <h2 className="stays-dark" style={{textAlign:'center',fontSize:'12px'}}>After clicking on the selected box, you will be directed to a form with questions about the selected inquiry.</h2>
    <br />
    <div className='modalnav' style={{display:'flex',flexWrap:'wrap',marginLeft:'5%',marginBottom:'50px'}}>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="book calendars" src='/img/book.svg'/>
    <br />
      Book Calendars
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img width="10px" className='icon-green' style={{marginRight:'0px'}} alt="ecology" src='/img/leaf.svg'/>
    <br />
      Ecology
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="branding" src='/img/pencil-ruler.svg'/>
    <br />
      Branding
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Graphic design" src='/img/pencil-alt.svg'/>
    <br />
    Graphic design
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Web pages" src='/img/code.svg'/>
    <br />
      Web pages
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Packaging" src='/img/box-open.svg'/>
    <br />
    Packaging
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="business calendars" src='/img/calendar-alt.svg'/>
    <br />
    Corporate calendars
    </a>


    {/*<a href="https://opengadzet.pl/" rel="noopener" target="_blank" className='ofnav1' >*/}
    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Advertising gadgets" src='/img/lightbulb.svg'/>
    <br />Advertising gadgets</a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Advertising prints" src='/img/map.svg'/>
    <br />
    Advertising prints
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="outdoor advertising" src='/img/object-ungroup.svg'/>
    <br />
    Outdoor advertising
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}}  alt="Product photography" src='/img/camera-retro.svg'/>
    <br />
    Product photography
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Exhibit" src='/img/cubes.svg'/>
    <br />
    Exhibit
    </a>

    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="Filming" src='/img/film.svg'/>
    <br />
    Filming
    </a>



    <a onClick={selectOffer} className='ofnav1' >
    <img  width="10px" className='icon-green' style={{marginRight:'0px'}} alt="VR" src='/img/vr-cardboard.svg'/>
    <br />
    VR
    </a>


    </div>


  </>);
}



function selectOffer(event){



  let name = event.target.innerText;
  name =  name.replace(/\s/g, '');
  console.log(name);

  console.log(document.getElementById(name));
  if (document.getElementById(name) !== null){

    navigate('#'+name);

  }

}

function selectEco(event){

  if (document.getElementById('Ekologia') !== null){
    navigate('#Ekologia');
  }

}


export default OffNav;
